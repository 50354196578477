.order-d > .list-group-item {
    position: relative;
    border: 1px solid #ddd;
    margin-bottom: 15px;
    z-index: 1;
}

.order-d .list-group-item:last-child {
    border-bottom:1px solid #ddd;
}

.order-d > .list-group-item > .list-group.list-group-flush .list-group-item {
    -webkit-box-shadow: 10px 9px 31px -2px rgba(0,0,0,0.22);
    -moz-box-shadow: 10px 9px 31px -2px rgba(0,0,0,0.22);
    box-shadow: 10px 9px 31px -2px rgba(0,0,0,0.22);
    margin-bottom: 15px;
}

.order-d > .list-group-item > h2, .h2 {
    font-size: 20px;
}

.order-summary {
    background-color: #343a40;
}

.order-summary h2 {
    margin: 0;
    color: #fff;
    font-size: 17px;
}
